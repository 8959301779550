export const lang = {


    title:'欧莱雅元宇宙展厅',
    account: '账号',
    password: '密码',
    nickname: '昵称',

    accountInfo: '请输入账号',
    passwordInfo: '请输入密码',
    passwordInfo1: '请再次输入密码',
    passwordInfo2: '两次输入密码不一致',
    nicknameInfo: '请输入昵称',
    login: '登录',
    register: '注册',
    language: '语言',
    zh: '中文',
    en: '英文',
    loginError1: '账号密码不能为空',
    loginError2: '密码错误',
    loginError3: '账号不存在',

    loginError4: '请勿重复登录',

    loginError5: '账号不为手机号',
    loginSuccess: '登陆成功',
    function1: "Expo场馆导览图",
    function2: "虚拟人物形象选择",
    function3: "交互动作",
    function4: '用户管理',
    logOut: '退出登录',
    close: '关闭',
    online: '在线人数',
    logOutInfo: '是否退出登录？',

    AccName: [
        "性别",
        "配饰",
        "头发",
        "帽子",
        "套装",
        "发色",
        "肤色"
    ],
    sendError: '您发送的内容不能为空',
    sendInfo: '请输入内容',
    send: '发送',
    peopleOnline: '人在线',
    world: '世界',
    clientError: '您还未进入语音区间，暂无权限',
    videoError: '您没有摄像头',
    micError: '您没有麦克风,进入房间失败',
    shareError: `当前已有人分享屏幕`,
    saveInfo: '保存成功',
    roam1:'序厅',
    roam2:'虚拟直播厅',
    roam3:'预见新产研赛道展馆',
    roam4:'玩美新体验赛道展馆',
    roam5:'引领运营赛道展馆',
    action1:'鼓掌',
    action2:'挥手',


    tongdao:'欧莱雅员工专属通道注册',

    cs1:'即将传送至'

}