import Bus from './bus'
import axios from "axios";
class chat {
    constructor() {
        this.ws = {},
            this.msgList = [
                []
            ],
            this.role = {

            },
            this.timmer = ""
        this.playerList = []
        this.user_power = 5; //0=游客 1=普通用户 2=认证用户 3=企业用户 4=企业管理员 5=总管理员
        this.user_status = 0; //用户状态
        this.user_group = 1; //展位编组id

        this.connectInfo = {
            //请求消息头

            CMD_VISITOR_LOGIN: 1059,
            CMD_COMMON_HEARTBEAT: 1000,//心跳 1000,ping
            CMD_COMMON_POSITION: 3,//实时同步位置信息 3,positions
            CMD_BUFFER_POSITION: 4,//缓存位置信息 4,positions
            CMD_COMMON_DEBUG: 1100,//用来调试
            CMD_USER_LOGIN: 1050,//登录 1050,name,id,info
            CMD_USER_CHATS: 1051,//公屏聊天 1051,messaged
            CMD_USER_CHAT: 1052,//私聊 1052,rev_id,message
            CMD_USER_QUIT: 1053,//退出 1053,null
            CMD_USER_SIT_DOWN: 1054,//坐下 1054,sitname,id
            CMD_USER_STAND_UP: 1055,//起立 1055,sitname,id
            CMD_USER_CUSTOM: 1056,//用户广播自定义消息 1056,custom_msg
            CMD_USER_CUSTOM_SEND: 1057,//用户向指定id自定义消息 1057,rev_id,custom_msg
            CMD_USER_LOGIN2: 1058,//游客登录 1058,name
            //响应消息头

            MSG_RELOGIN: 198,//重复登录,现有账户退出。198,id
            MSG_NULL: 200,//无消息头
            MSG_LOGIN_SUCCESS: 201,//登录成功 201,id
            MSG_NEWPLAYER: 202,//新用户加入 202,playerinfo
            MSG_QUIT: 203,//退出 203,id
            MSG_POS: 204,//位置信息  204,id,positions
            MSG_BUF_POS: 205,//缓存位置信息 205,positions0,positions1,....
            MSG_PLAYERS: 206,//用户列表 202,json_encode(players)  $player = array("fd" => $this->fd,"id" => $pid,"name" => $cur_name,"info" => $info);
            MSG_CHATS: 207,//公屏聊天 207,id,msg 
            MSG_CHAT: 208,//私聊 208,send_id,rev_id,msg
            MSG_SITS: 209,//当前所有座位信息 209,json_encode(sits) $sit[$sitname] = id;
            MSG_SIT_DOWN: 210,//坐下 210,sitname,id
            MSG_STAND_UP: 211,//起立 211,sitname,id
            MSG_CUSTOM: 212,//用户自定义消息 212,id,custom_msg
            MSG_CLEAR_SITDOWNS: 214,//制清理入座信息 214,
            MSG_DEBUG: 300//用来调试
        }

    }
    login(role, rid) {
        var that = this

        console.log(role)
        function wsConnect(role) {

            // var num = Number(localStorage.getItem('olytype')) + document.o2.websocket
            var num = document.o2.websocket
            that.ws = new WebSocket(`wss://api.qdifeng.com:9220`);
            that.ws.onopen = function () {

                if (role.type) {
                    that.ws.send(`${that.connectInfo.CMD_VISITOR_LOGIN},${role.name},${role.auth}`)
                } else {
                    that.ws.send(`${that.connectInfo.CMD_USER_LOGIN},${role.name},${Number(role.id)},${role.auth}`)
                }

                // that.ws.send(`${that.connectInfo.CMD_USER_LOGIN},${role.name},${Number(role.id)},${role.auth}`)

                Bus.$emit('loginS', '')
                that.ws.onmessage = function (evt) {
                    if (evt.data instanceof Blob) {
                        Bus.$emit('playermsg', evt);
                        return;
                    }

                    var msg = evt.data.split(',')

                    console.log(msg[0])
                    Bus.$emit('playermsg', evt)


                    if (msg[0] == that.connectInfo.MSG_LOGIN_SUCCESS) {
                        if (msg[1]) {
                            that.role.id = msg[1]

                            document.client.login(msg[1])
                        }

                        if (msg[2]) {
                            that.role.fd = msg[2]
                        }
                    }

                    if (msg[0] == 206) {
                        var reg = new RegExp('206,')
                        that.playerList = []
                        var list = JSON.parse(evt.data.replace(reg, ""))
                        list.forEach(element => {
                            var obj = JSON.parse(element)
                            obj.flag = false
                            that.playerList.push(obj)
                        });
                        console.log(that.playerList)
                        Bus.$emit('playerList', that.playerList)
                    }


                    if (msg[0] == that.connectInfo.MSG_CHATS) {
                        var name = that.playerList.filter((el) => el.id === msg[1])[0].name
                        that.msgList[0].push({
                            name,
                            msg: msg[2],
                        })
                        Bus.$emit('msgList', that.msgList)
                    }


                    if (msg[0] == that.connectInfo.MSG_NEWPLAYER) {

                        that.playerList.push({
                            id: msg[1],
                            name: msg[2],
                            vip: msg[3],
                            fd: msg[4],
                            flag: true
                        })
                    }


                    if (msg[0] == that.connectInfo.MSG_QUIT) {
                        console.log(that.playerList)
                        var value = that.playerList.findIndex((el) => el.id == msg[1])
                        that.playerList.splice(value, 1)

                    }



                    if (msg[0] == that.connectInfo.MSG_CHAT) {

                        var name = that.playerList.filter((el) => el.id === msg[1])[0].name
                        that.msgList.push([])
                        var sendid = that.playerList.filter((el) => el.id === msg[1])[0].name
                        var reid = that.playerList.filter((el) => el.id === msg[2])[0].name
                        var m = msg[3]
                        var value = msg[4] * 1

                        if (that.role.name == sendid) {
                            that.msgList[value].push({
                                name: sendid,
                                msg: m
                            })
                            Bus.$emit('msgList', that.msgList)
                        } else if (that.role.name == reid) {
                            Bus.$emit('reciveMsg', {
                                name: sendid,
                                msg: m,
                                from: sendid,
                                id: msg[1]
                            })
                        }


                    }


                    if (msg[0] == that.connectInfo.MSG_RELOGIN) {



                        if (that.role.fd == msg[1]) {
                            localStorage.removeItem("info");
                            location.reload();
                            done();
                        }
                        var value1 = that.playerList.findIndex((el) => el.fd == msg[1])
                        that.playerList.splice(value1, 1)
                    }











                }
            };


            that.ws.onclose = function (e) {
                that.ws.send(`${that.connectInfo.CMD_USER_QUIT}`)

            };
        }


        wsConnect(role)
        setInterval(() => {
            setTimeout(() => {
                if (this.ws.readyState == 1) {
                    // 已经链接并且可以通讯
                    this.ws.send(`${this.connectInfo.CMD_COMMON_HEARTBEAT},ping`)
                } else if (this.ws.readyState == 2) {
                    this.ws.onclose = function (e) {
                        wsConnect(role)
                    };
                } else if (this.ws.readyState == 3) {
                    // 连接已关闭或者没有链接成功
                    wsConnect(role)
                }
            }, 500)

        }, 30000)

    };

    searchName(e) {
        if (this.playerList.filter((el) => el.id == e)) {
            var name = this.playerList.filter((el) => el.id == e)[0].name
        } else {
            var name = el.id
        }

        return name
    }
    send(e) {
        this.ws.send(e)
    }
}
export { chat }
